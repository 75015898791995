import React, { useState } from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { CUSTOMERS_QUERY, PORTFOLIO_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import { Loader } from '../components';
import { useQuery } from '@apollo/react-hooks';
import { connection_limit } from '../config/config';

const PortfolioDetails = ({ render, match: { params } }) => {
  const { portfolioNumber, customerEmail } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('portfolio-details');

  const loanHistoryInput = {
    searchParams: {
      actionBy: useCustomState(),
      commentLike: useCustomState(),
      newPortfolioStatus: useCustomState(null),
      oldPortfolioStatus: useCustomState(null),
    },
    isSearchPerformed: useCustomState(false),
  };

  const { data } = useQuery(CUSTOMERS_QUERY, {
    variables: {
      email: customerEmail,
    },
    fetchPolicy: 'network-only',
  });

  let interest, facilityInfo, isVisible, customerName;

  if (data && data.viewer) {
    customerName = data?.viewer?.account?.customers?.nodes[0]?.name;
  }

  if (data?.viewer?.account?.customers?.nodes[0]?.accountAttribute?.length) {
    facilityInfo =
      data?.viewer?.account?.customers?.nodes[0]?.accountAttribute[0]?.data;
  }

  const portfolioDetailsPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    selectedOfflineRepaymentData: useCustomState({}),
  };

  const reFetch = async (refetch, searchParams) => {
    refetch(setSearchParams(searchParams));
  };

  const handleHideToast = e => {
    e.preventDefault();

    portfolioDetailsPrompt.showToast.setValue(false);
  };

  const setSearchParams = searchParams => {
    const { actionBy, commentLike, newPortfolioStatus, oldPortfolioStatus } =
      searchParams;

    return {
      actionBy: actionBy.value ? actionBy.value : undefined,
      commentLike: commentLike.value ? commentLike.value : undefined,
      newPortfolioStatus: newPortfolioStatus.value
        ? newPortfolioStatus.value
        : undefined,
      oldPortfolioStatus: oldPortfolioStatus.value
        ? oldPortfolioStatus.value
        : undefined,
      first: connection_limit.value,
    };
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    loanHistoryInput.isSearchPerformed.setValue(true);
    await reFetch(refetch, loanHistoryInput.searchParams);
  };

  const handleFetchMore = (e, fetchMore, data) => {
    e.preventDefault();

    setIsLoading(true);
    fetchMore({
      variables: {
        cursor: data.portfolio.histories.pageInfo.endCursor,
        ...setSearchParams,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        setCurrentTab('loan-history');
        const newNodes = fetchMoreResult.portfolio.histories.nodes;
        const pageInfo = fetchMoreResult.portfolio.histories.pageInfo;

        return {
          portfolio: {
            id: prev.portfolio.id,
            __typename: prev.portfolio.__typename,
            histories: {
              __typename: prev.portfolio.histories.__typename,
              nodes: [...prev.portfolio.histories.nodes, ...newNodes],
              pageInfo,
            },
          },
        };
      },
    });
  };

  return (
    <Query
      query={PORTFOLIO_QUERY}
      variables={{
        portfolioNumber: portfolioNumber,
        transactionWhere: {
          type_in: ['Manual Repayment', 'Repayment', 'Reversal'],
        },
        where: {
          commentLike: loanHistoryInput.searchParams.commentLike.value,
          actionBy: loanHistoryInput.searchParams.actionBy.value,
          newPortfolioStatus: loanHistoryInput.searchParams.newPortfolioStatus
            .value
            ? loanHistoryInput.searchParams.newPortfolioStatus.value
            : null,
          oldPortfolioStatus: loanHistoryInput.searchParams.oldPortfolioStatus
            .value
            ? loanHistoryInput.searchParams.oldPortfolioStatus.value
            : null,
        },
        first: connection_limit.value,
      }}
      notifyOnNetworkStatusChange={true}
      errorPolicy='all'
    >
      {({ loading, error, data: portfolioData, refetch, fetchMore }) => {
        if (loading) {
          return <Loader />;
        }

        if (portfolioData) {
          interest =
            portfolioData.portfolio.fullAmount -
            portfolioData.portfolio.baseAmount;
          isVisible = localStorage.getItem('slug') === 'heliumhealth';
        }

        return render({
          portfolioData,
          error,
          portfolioInterest: interest,
          input: portfolioDetailsPrompt,
          handleHideToast,
          customerName,
          facilityInfo,
          isVisible,
          refetch,
          handlePagination: e => handleFetchMore(e, fetchMore, portfolioData),
          loanHistoryLoading: isLoading,
          currentTab,
          setCurrentTab,
          runSearchQuery: e => handleRefetch(e, refetch),
          loanHistoryInput,
        });
      }}
    </Query>
  );
};

export default withRouter(PortfolioDetails);
