import { graphql } from '@/__generated/gql';

export const GET_LOAN_PRODUCTS = graphql(/* GraphQL */ `
  query GetPolicies(
    $name: String
    $loanType: String
    $status: String
    $minLoanAmount: Float
    $maxLoanAmount: Float
    $minLoanDuration: Int
    $maxLoanDuration: Int
    $durationType: String
    $repaymentType: String
    $after: ConnectionCursor
  ) {
    viewer {
      account {
        id
        policies(
          after: $after
          where: {
            status: $status
            name_contains: $name
            minLoanAmount: $minLoanAmount
            maxLoanAmount: $maxLoanAmount
            minLoanDuration: $minLoanDuration
            maxLoanDuration: $maxLoanDuration
            durationTypeName: $durationType
            repaymentTypeName: $repaymentType
            loanTypeName: $loanType
          }
        ) {
          nodes {
            id
            name
            status
            loanType
            minLoanAmount
            retroActiveFeeRefund
            maxLoanAmount
            minLoanDuration
            maxLoanDuration
            durationType
            repaymentType
            graduatedLoanCycles {
              amount
            }
            requiresPreQualificationId
            preQualificationId
            isPublic
            scoreCardConfig {
              scoreCardId
              preQualificationId
              approvalConfig {
                onFail
                onPass
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  }
`);
