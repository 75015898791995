import { graphql } from '@/__generated/gql';

export const UPDATE_LOAN_PRODUCT = graphql(/* GraphQL */ `
  mutation UpdateLoanProduct($data: UpdatePolicyInput!, $policyId: ID!) {
    policyUpdate(where: { id: $policyId }, data: $data) {
      recordId
      record {
        id
        name
        loanType
        description
        durationType
        minLoanAmount
        maxLoanAmount
        minLoanDuration
        maxLoanDuration
        maxLateFeeDuration
        applicationForm
        scoreCard
        requiresScoreCard
        retroActiveFeeRefund
        interestCalculationMethod {
          name
        }
        interestRate {
          calcBy
          value
        }
        processingFee {
          value
          type
          calcBy
        }
        processingRefundFee {
          value
          type
          calcBy
        }
        lateFeeCalculation {
          lateFeeChargeOn
          lateFeeFrequency
          penaltyDelay
          rate {
            calcBy
            name
            value
          }
          applyFlatLateFeePerPeriod
        }
        repaymentType
        allowEarlyRepayment
        graduatedLoanCycles {
          amount
          interestRate
          maxLoanDuration
          repaymentCount
        }
        approvalWorkflow {
          id
        }
        baseRuleSet {
          id
        }
        offerLetterEnabled
        validityInDays
        loanCategoryId
        requiresPreQualificationId
        preQualificationId
        processingFeeName
        repaymentInterval
      }
    }
  }
`);
